<template>
 <Containers class="w-full" :status="status" @funEnd='funEnd' >
    <!-- banner开始 -->
    <div class="w">
      <banner class="w-full wow fadeInDown" title="CRAFT" bgImg="https://s3.moke.cloud/shuangmei/7286add6-096d-437f-8232-0e82fd3a44ce.jpg" ></banner>
    </div>
    <!-- banner结束 -->

    <!--  开始 -->
    <div class="synopsis w row p-0 m-0 " >
      <div class=" col-md-1 p-0 m-0"></div>
      <div class="center col-md-8 p-0 m-0 ">
        <headlines class="headlines font-XQ" title='精雕細琢 細烹慢制 八道工藝呈絕味' english="CRAFT" color="#fff" align='left' ></headlines>
        <div class="text-whiteMilk mt-25 wow fadeInUp">爽利可口的豆沙牛乳问世之后，经阿柱家几代人的改良与传承，秉承着最初对食材的精挑细选的坚持，再加上日趋成熟的一道道匠心手工，最终八道完整的制作工序传承了下来。</div>
        <div class="text-whiteMilk mt-25 wow fadeInUp">一颗颗精挑细选过的高山毛绿豆，要经过“浸泡”“杀青”“过滤”“蒸制”“熬煮”“蜜制”“破壁”“冷却”后再加入纯粹的牛乳后,最终成为一杯看似平凡，却又在台湾街头广为流传的经典饮品。</div>
      </div>
    </div>
    <!-- 结束 -->
    <!-- 流程 开始 -->

    <div class="step w p-0 m-0">
      <a-row type="flex" justify="space-between" v-for="(item,index) in step" :key="index">
        <a-col class="" :sm="24" :md="24">
          <div class="step-row">
            <a-row type="flex" justify="space-between" align="middle" :gutter="[20,20]">
              <a-col class="text-center" :sm="24" :md="8" >
                <img class="wow fadeInUp" :src="item.left" alt="">
              </a-col>
              <img class="pc-show" style="width: 117px;height:45px" :src="item.center" alt="">
              <a-col class="text-center" :sm="24" :md="8">
                <img class="wow fadeInUp" data-wow-delay=".3s" :src="item.right" alt="">
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- 流程 结束 -->

 </Containers>
</template>

<script>
import { WOW } from 'wowjs'

import banner from '@/components/banner'
import headlines from '@/components/headlines'

export default {
  metaInfo: {
    title: '工艺流程_双美豆沙牛乳官方网站',
    meta: [
      {
        name: 'keywords',
        content: '双美豆沙牛乳工艺流程_双美豆沙牛乳官网'
      },
      {
        name: 'description',
        content: '本页面为您提供双美豆沙牛的工艺流程，精雕细琢 细烹慢制 八道工艺呈绝味'
      }
    ]
  },
  data () {
    return {
      status: 'success',
      step: [
        {
          left: require('../assets/images/craft1.png'),
          center: require('../assets/images/step-bottom.png'),
          right: require('../assets/images/craft2.png')
        },
        {
          left: require('../assets/images/craft3.png'),
          center: require('../assets/images/step-top.png'),
          right: require('../assets/images/craft4.png')
        },
        {
          left: require('../assets/images/craft5.png'),
          center: require('../assets/images/step-bottom.png'),
          right: require('../assets/images/craft6.png')
        },
        {
          left: require('../assets/images/craft7.png'),
          center: require('../assets/images/step-top.png'),
          right: require('../assets/images/craft8.png')
        },
      ]
    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    banner,
    headlines,
  },
  computed: {
  },
  methods: {
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)
    },
    async getInit () {
      try {
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

.synopsis{
  padding: 100px 0 120px !important;
  @include hamburger {
    padding: 30px 0 60px !important;
  }
}
.step{
  box-sizing: border-box;
  background-color: #FCFAF0;
  padding: 3vw 11vw !important;
  @include hamburger {
    padding: 20px !important;
  }
  .step-row{
    margin: 3vw 0;
    @include hamburger {
      margin: 20px 0 !important;
    }
  }
}
</style>
